import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const RelatedContent = (props) => {
  return (
    <Container className="related-content section mt-2 pt-1">
      {props.page === "social-toolkit" && (
        <Row>
          <Col xs={12} className="m-auto ">
            <h2 className="title">RELATED CONTENT</h2>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} className="m-auto">
          <Container>
            <Row className="justify-content-lg-between mb-standard">
            <div class="card-callout mb-3 mb-lg-0 col-lg-4 col-12">
              <a class="d-flex flex-column justify-content-between" href="../../landscape/hiv-testing">
                <div>
                  <h3>HIV Testing</h3>
                  <p class="title-one-line">Learn how HIV testing is the first step in getting people
                  diagnosed, linking them to care, and providing an opportunity
                  to discuss treatment or prevention options.</p>
                </div>
                  <span><span>Learn more</span>&nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></span>
              </a>
            </div>

            <div class="card-callout mb-3 mb-lg-0 col-lg-4 col-12">
              <a class="d-flex flex-column justify-content-between" href="../../landscape/ehe">
                <div>
                  <h3>Plans for Ending the Epidemic</h3>
                  <p class="title-one-line">Discover more about the goals and coordinated efforts to
                  address the HIV epidemic across government agencies in the
                  United States.</p>
                </div>
                  <span><span>Discover more</span>&nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></span></a>
            </div>

            <div class="card-callout mb-3 mb-lg-0 col-lg-4 col-12">
              <a class="d-flex flex-column justify-content-between" href="../../community-involvement/voices-of-strength">
                <div>
                  <h3>Voices of Strength Video Series</h3>
                  <p class="title-one-line">Watch the personal stories of people who combat stigma through their perseverance and strength.</p>
                </div>
                  <span><span>Watch more</span>&nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></span></a>
            </div>

            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default RelatedContent;
