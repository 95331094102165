import * as React from "react";
import { Component } from "react";
import { Button, Accordion, Card } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import accordionContentList from "./copyList";
import downloadjs from "downloadjs";

class Accordions extends Component {
  handleDownload = (fileName) => {
    downloadjs(fileName);
  };

  downloadImages = (imageUrls) => {
    const triggerDownload = (url, fileName) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    imageUrls.forEach((imageUrl, index) =>
      triggerDownload(imageUrl, `image${index + 1}.jpg`)
    );
  };

  render() {
    const ContextAwareToggle = ({ children, eventKey, callback }) => {
      const currentEventKey = useContext(AccordionContext);
      const isCurrentEventKey = currentEventKey === eventKey;
      const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
      );
      return (
        <button
          type="button"
          className={`${isCurrentEventKey ? "active" : ""}`}
          onClick={decoratedOnClick}
        >
          {children}
        </button>
      );
    };

    return (
      <>
        <Accordion className="body" defaultActiveKey="0">
          {
            accordionContentList.map((item, i) => {
              const totalCards = item?.cards?.length;
              return(
                <Card key={i}>
                  <Card.Header>
                    <ContextAwareToggle
                        as={Button}
                        variant="link"
                        eventKey={item.eventKey}
                        callback={() => this.props.scrollToSection(item.scrollTo)}
                      >
                        <h2
                          data-anchor={item.scrollTo}
                          className="title"
                          dangerouslySetInnerHTML={{__html: item.header}}
                        ></h2>
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={item.eventKey}>
                    <Card.Body>
                      <Container className="pl-0">
                        <Row>
                          <Col xs={12} lg={12} className="pb-4">
                            <Row>
                              {
                                item?.cards.map((card, i) => {
                                return (
                                  <Col xs={12} lg={totalCards > 2 ? 4 : 6 } key={i}>
                                    <div className="card-item">
                                      {card.title && <p className="title">
                                        <strong>{card.title}</strong>
                                      </p>}
                                      {(card.image || card.imageAlt) && <img
                                        className="card-img"
                                        src={card.image}
                                        alt={card.imageAlt}
                                      />}
                                      {card.downloadLink && <p
                                        className="download secondary button large"
                                        onClick={() =>
                                          this.handleDownload(card.downloadLink)
                                        }
                                      >
                                        <strong>Download</strong>{" "}
                                    
                                      </p>}
                                      {
                                        card.descriptionTitle && 
                                          <p className="download-caption">
                                            <strong 
                                              dangerouslySetInnerHTML={{__html: card.descriptionTitle}}>
                                            </strong>
                                          </p>
                                      }
                                      {
                                        card.description &&
                                          <p className="download-caption-text"
                                            dangerouslySetInnerHTML={{__html: card.description}}
                                          >
                                          </p>
                                      }
                                    </div>
                                  </Col>
                                )
                              })}
                              {(item.captionTitle || item.captionDescription) &&
                                <Col xs={12} lg={12} className="pb-4">
                                  {item.captionTitle &&
                                    <p className="download-caption">
                                      <strong 
                                        dangerouslySetInnerHTML={{__html: item.captionTitle}}>
                                      </strong>
                                    </p>
                                  }
                                  {item.captionDescription &&
                                    <p className="download-caption-text"
                                      dangerouslySetInnerHTML={{__html: item.captionDescription}}
                                    ></p>
                                  }
                                </Col>
                              }
                              {(item.defaultContent) &&
                                <Col xs={12} lg={12} className="pb-4">
                                  {item.defaultContent &&
                                    <div
                                      dangerouslySetInnerHTML={{__html: item.defaultContent}}
                                    ></div>
                                  }
                                </Col>
                              }
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Body>  
                  </Accordion.Collapse>
                </Card>
              )
            })
          }
        </Accordion>
      </>
    );
  }
}

export default Accordions;
