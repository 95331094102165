import GileadWorldaidsday1 from "../../images/Gilead_worldaidsday_1.jpg";
import GileadWorldaidsday2 from "../../images/Gilead_worldaidsday_2.jpg";

var todayDate = new Date();
var year = todayDate.getFullYear();
var month = ("0" + (todayDate.getMonth() + 1)).slice(-2);
var day = ("0" + (todayDate.getDate() + 1)).slice(-2);

todayDate = new Date(year + "-" + month + "-" + day);

// Each card has CaptionTitle and Description, in case that only one caption and description is needed for all cards, 
//   let the cards descriptionTitle and description empty and fill captionTitle and captionDescription.
// With this version you can have also description by card and description by accordion item, depending on yours needs.
// To order the accordion items, it sorts by orderDate ascendant, so in case that you need to prioritize any item, just 
//    update this attribute with a date that works for your order.
// defaultContent: can be used in those cases where the cards aren't needed and you need to add custom html tags with content.

const accordionContentList = [
  {
    eventKey: "",
    header: "National Women and Girls HIV/AIDS Awareness Day",
    cards: [
      {
        title: "Image Option 1",
        image: "/Gilead_NWGHAAD_1-display.png",
        imageAlt:
          "Women accounted for nearly 1 out of 5 new HIV diagnoses in the US in 2020",
        downloadLink: "/Gilead_NWGHAAD_1.png",
        descriptionTitle: "Caption",
        description:
          "NWGHAAD is an opportunity to honor the critical role women play in helping end the HIV epidemic. Share this graphic to help start the conversation about HIV and women in your community. #HIVAIDS #HIVAndWomen #HIVTesting #KnowYourStatus #StopHIVTogether #ThisIsWhatWeLiveFor",
      },
      {
        title: "Image Option 2",
        image: "/Gilead_NWGHAAD_2.png",
        imageAlt:
        "Only 10% of women, assigned female at birth and aged 16+, who could benefit from PrEP were prescribed PrEP in the US in 2019",
        downloadLink: "/Gilead_NWGHAAD_2.png",
        descriptionTitle: "Caption",
        description:
        "#NWGHAAD is a day to share vital information about disparities in HIV that women and girls continue to face in prevention, awareness, and access to HIV care. Share this post to start a conversation. #HIVAIDS #HIVAndWomen #KnowYourStatus #StopHIVTogether #ThisIsWhatWeLiveFor ",
      },
    ],
    captionTitle: "",
    captionDescription: "",
    defaultContent: "",
    scrollTo: "national-women",
    date: "2022-02-23",
    orderDate: "2022-02-23",
    endDate: todayDate,
    isActive: true,
  },
  {
    eventKey: "",
    header: "National Black HIV/AIDS Awareness Day",
    cards: [
      {
        title: "Image Option 1",
        image: "/Gilead_NBHAAD_1.png",
        imageAlt: "Black Americans continue to be disproportionately impacted by HIV, according to 2022 CDC data.",
        downloadLink: "/Gilead_NBHAAD_1.png",
        descriptionTitle: "Caption",
        description: "Progress in HIV care has not been equally achieved. Share this post, raise awareness, and start a conversation about HIV today. #GileadHIV #HIVAwareness #HIV #AIDS #HIVAIDS #EndTheHIVEpidemic #HIVCare #NBHAAD #StopHIVTogether",
      },
      {
        title: "Image Option 2",
        image: "/Gilead_NBHAAD_2.png",
        imageAlt: "Black Americans are approximately 8 times less likely to be on PrEP medicines than White Americans, according to 2022 CDC data.",
        downloadLink: "/Gilead_NBHAAD_2.png",
        descriptionTitle: "Caption",
        description: "Of those who may benefit from PrEP, just 8% of Black Americans were prescribed PrEP in 2019 compared to 63% of White Americans. #GileadHIV #HIVAwareness #HIV #AIDS #HIVAIDS #EndTheHIVEpidemic #HIVCare #NBHAAD #StopHIVTogether",
      },
    ],
    captionTitle: "",
    captionDescription: "",
    defaultContent: "",
    scrollTo: "national-black",
    date: "2022-05-23",
    orderDate: "2022-05-23",
    endDate: todayDate,
    isActive: true,
  },
  {
    eventKey: "",
    header: "National Latinx AIDS Awareness Day",
    cards: [
      {
        title: "Image Option 1",
        image: "/latinx-1-desktop.png",
        imageAlt: "",
        downloadLink: "/latinx-1-desktop.png",
        descriptionTitle: "Caption",
        description: "Poverty and language barriers, among other factors, may hinder access to HIV care for some Hispanic/Latino people. Share this post to drive awareness of this disparity and help bridge the gap. #HIVAIDS #HIVAwareness #EndTheHIVEpidemic #NLAAD #StopHIVTogether #ThisIsWhatWeLiveFor",
      },
      {
        title: "Image Option 2",
        image: "/latinx-2-desktop.png",
        imageAlt: "",
        downloadLink: "/latinx-2-desktop.png",
        descriptionTitle: "Caption",
        description: "Although progress has been made, Hispanics/Latinos accounted for 29% of new HIV diagnoses despite making up only 18% of the US population in 2019. Share today to help address this disparity. #HIVAIDS #HIVAwareness #EndTheHIVEpidemic #NLAAD #StopHIVTogether #ThisIsWhatWeLiveFor",
      },
    ],
    captionTitle: "",
    captionDescription: "",
    defaultContent: "",
    scrollTo: "transgender",
    date: "2022-10-15",
    orderDate: "2022-10-15",
    endDate: todayDate,
    isActive: true,
  },
  {
    eventKey: "",
    header: "World AIDS Day",
    cards: [
      {
        title: "Image Option 1",
        image: GileadWorldaidsday1,
        imageAlt: "80% of new HIV infections in the US in 2018 were transmitted by people who didn’t know they had HIV or were not in care.",
        downloadLink: GileadWorldaidsday1,
        descriptionTitle: "",
        description: "",
      },
      {
        title: "Image Option 2",
        image: GileadWorldaidsday2,
        imageAlt: "Goals of the EHE Plan, 75% reduction in new HIV infections by 2025 and at least 90% reduction by 2030.",
        downloadLink: GileadWorldaidsday2,
        descriptionTitle: "",
        description: "",
      },
    ],
    captionTitle: "Caption",
    captionDescription: "This World AIDS Day, we remember those lost to AIDS-related illnesses and acknowledge both the progress made and the work still ahead. Share this post to raise awareness and help end the HIV epidemic for everyone, everywhere. #StopHIVTogether #HIVAIDS #HIVAwareness #WorldAIDSDay",
    defaultContent: "",
    scrollTo: "women",
    date: "2022-12-31",
    orderDate: "2022-12-31",
    endDate: todayDate,
    isActive: true,
  },
  {
    eventKey: "",
    header: "National HIV Testing Day",
    cards: [
      {
        title: "Image Option 1",
        image: "/Gilead_NHTD_Toolkit_v8-1.jpg",
        imageAlt: "Today we acknowledge the critical need for HIV testing",
        downloadLink: "/Gilead_NHTD_Toolkit_v8-1.jpg",
        descriptionTitle: "",
        description: "",
      },
      {
        title: "Image Option 2",
        image: "/Gilead_NHTD_Toolkit_v8-2.jpg",
        imageAlt: "Per the CDC, there are approximately 1.2M people living with HIV in the US. About 13% are not aware of their status.",
        downloadLink: "/Gilead_NHTD_Toolkit_v8-2.jpg",
        descriptionTitle: "",
        description: "",
      },
      {
        title: "Image Option 3",
        image: "/Gilead_NHTD_Toolkit_v8-3.jpg",
        imageAlt: "Per the CDC, nearly 40% of new HIV infections are transmitted by people who don't know they have the virus.",
        downloadLink: "/Gilead_NHTD_Toolkit_v8-3.jpg",
        descriptionTitle: "",
        description: "",
      },
    ],
    captionTitle: "Caption",
    captionDescription: "National HIV Testing Day is June 27. No matter the test results, there's something to do, including talking to a healthcare professional about treatment and preventative options that are right for you. #GileadHIV #HIVAwareness #HIV #AIDS #HIVAIDS #EndTheHIVEpidemic #HIVCare #HIVTesting #HIVTestingDay #StopHIVTogether",
    defaultContent: "",
    scrollTo: "gay",
    date: "2022-06-24",
    orderDate: "2021-06-24",
    endDate: todayDate,
    isActive: true,
  },
  {
    eventKey: "",
    header: "Southern HIV/AIDS Awareness Day",
    cards: [
      {
        title: "Image Option 1",
        image: "/Gilead_SHAAD_1.png",
        imageAlt: "52% of HIV diagnoses in the United States occurred in the South in 2019.",
        downloadLink: "/Gilead_SHAAD_1.png",
        descriptionTitle: "Caption",
        description: "The South is the epicenter of the HIV epidemic in the US, yet access to HIV prevention services and care in the South is lacking. Raise awareness to help close the gaps. Share this post today. #ThisIsWhatWeLiveFor #HIVAIDS #HIVAwareness #EndTheHIVEpidemic #SHAAD #StopHIVTogether",
      },
      {
        title: "Image Option 2",
        image: "/Gilead_SHAAD_2.png",
        imageAlt: "~48% of the highestburden counties targeted by the Ending the HIV Epidemic (EHE) initiative are in the South, according to 2016 data.",
        downloadLink: "/Gilead_SHAAD_2.png",
        descriptionTitle: "Caption",
        description: "Southern states bear the greatest burden of HIV. The EHE Initiative represents a commitment to helping address this disparity. Share this post to help raise awareness of HIV in the South. #ThisIsWhatWeLiveFor #HIVAIDS #HIVAwareness #EndTheHIVEpidemic #SHAAD #StopHIVTogether",
      },
    ],
    captionTitle: "",
    captionDescription: "",
    defaultContent: "",
    scrollTo: "black",
    date: "2022-08-20",
    orderDate: "2022-08-20",
    endDate: todayDate,
    isActive: true,
  },
  {
    eventKey: "",
    header: 'National Gay<span className="d-block d-md-block-inline"> Men\'s HIV/AIDS </span> <span className="d-block d-md-block-inline">Awareness Day</span>',
    cards: [
      {
        title: "Image Option 1",
        image: "/Gilead_NGMHAAD_24_1.jpg",
        imageAlt: "Did you know? In the past, black MSM* have experienced higher rates of HIV stigma. Black MSM can benefit from status-neutral care, which reduces stigma and racial barriers.",
        downloadLink: "/Gilead_NGMHAAD_24_1.jpg",
        descriptionTitle: "",
        description: "",
      },
      {
        title: "Image Option 2",
        image: "/Gilead_NGMHAAD_24_2.jpg",
        imageAlt: "The lifetime chance of Black MSM* in the US acquiring HIV has reduced from 1 in 2 to 1 in 3.",
        downloadLink: "/Gilead_NGMHAAD_24_2.jpg",
        descriptionTitle: "",
        description: "",
      },
    ],
    captionTitle: "Caption",
    captionDescription: "Together, there's more we can do to raise awareness about the impact of HIV on gay and bisexual men. Share this post for National Gay Men's HIV/AIDS Awareness Day on September 27. #NGMHAAD #Together #StopHIVTogether #HIVPrevention",
    defaultContent: "",
    scrollTo: "hispanic",
    date: "2022-09-30",
    orderDate: "2021-09-30",
    endDate: todayDate,
    isActive: true,
  },
];

//ordered accordion
//  filters by active
//  filters by endDate of the event. Note: todayDate means this even has no end date
//  orders the events by orderDate, if a new order is needed just modify the dates of the events
//    with the order needed
//  adds the eventKey attribute to handle button click on accordions.

const milisecondsInADay = 86400000;
let orderedAccordion = [
  ...accordionContentList
  .filter((event) => event.isActive)
  .filter((event) => {
    //Adds 1 day in milliseconds to match the date after convertion.
    const eventEndDateTime = new Date(event.endDate).getTime() + milisecondsInADay;
    const todayDateTime = todayDate.getTime();
    return eventEndDateTime >= todayDateTime
  })
  .sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate))
  .map((a, i) => {
    return {...a, eventKey: `${i}`}}
    )
  ];

export default orderedAccordion;
