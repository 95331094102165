import * as React from "react";
import { Component } from 'react';
import Layout from '../../components/global/layout.js'
import Content from '../../components/social-toolkit/content.js'
import '@fortawesome/fontawesome-free/css/all.css'; 
import '../../sass/app.scss';

class SocialMediaToolkit extends Component {
  render() {
    return (
      <>
        <Layout section="community-involvement" page="social-toolkit">
          <Content section="community-involvement" />
        </Layout>
      </>
    )
  }
}

export default SocialMediaToolkit;